import React from "react"
import FadeIn from "react-fade-in"

import iconContact from "../../static/images/icons/contact.svg"
import iconEmail from "../../static/images/icons/email.svg"
import iconDiscord from "../../static/images/icons/discord.svg"
import iconGithub from "../../static/images/icons/github.svg"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import FormFormik from "../components/form"

const Contact = () => (
  <Container fluid id="contact">
    <FadeIn>
      <Row>
        <Col xl="6" className="d-none d-xl-block">
          <Container className="contact-container" fluid="lg">
            <Row>
              <Col xs="auto">
                <img
                  src={iconContact}
                  alt="Icon"
                  width="24"
                  className="icon blu-shadow"
                />
              </Col>
              <Col xs="auto">
                <h1>CONTACT ME</h1>
              </Col>
            </Row>
            <FormFormik />
          </Container>
        </Col>
        <Col>
          <Container className="contact-links" fluid>
            <Row>
              <h2>I look forward to hearing from you.</h2>
            </Row>
            <Row>
              <Col sm="auto">
                <img
                  src={iconEmail}
                  alt="Icon"
                  width="24"
                  className="icon liblue-shadow"
                />
              </Col>
              <Col sm="auto">
                <a href="mailto:declininglotus@gmail.com">
                  declininglotus@gmail.com
                </a>
              </Col>
            </Row>
            <Row>
              <Col sm="auto">
                <img
                  src={iconDiscord}
                  alt="Icon"
                  width="24"
                  className="icon liblue-shadow"
                />
              </Col>
              <Col sm="auto">
                <a href="/">Lotus#1234</a>
              </Col>
            </Row>
            <Row>
              <Col sm="auto">
                <img
                  src={iconGithub}
                  alt="Icon"
                  width="24"
                  className="icon liblue-shadow"
                />
              </Col>
              <Col sm="auto">
                <a href="https://github.com/DecliningLotus">@DecliningLotus</a>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </FadeIn>
  </Container>
)

export default Contact
