import React from "react"
import FadeIn from "react-fade-in"

import iconPortfolio from "../../static/images/icons/portfolio.svg"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import PortfolioImage from "../components/portfolioImage"

const Portfolio = () => (
  <Container id="portfolio" fluid>
    <FadeIn>
      <Row className="portfolio-title">
        <Col xs="auto">
          <img
            src={iconPortfolio}
            width="24"
            alt="Icon"
            className="icon yel-shadow"
          />
        </Col>
        <Col xs="auto" className="d-none d-sm-block title-margin">
          <h1>FEATURED PROJECTS</h1>
        </Col>
        <Col xs="auto" className="d-sm-none title-margin">
          <h1>PROJECTS</h1>
        </Col>
      </Row>
    </FadeIn>
    <FadeIn>
      <Row className="justify-content-md-center">
        <Col xl="4">
          <a href="https://collabstr.com/">
            <PortfolioImage imageName={"collabstr"}>
              <div className="portfolio-text-mask">
                <div className="mask"></div>
                <div className="portfolio-text text-center">
                  <h3>COLLABSTR</h3>
                  <p>HTML CSS</p>
                  <Button className="portfolio-button">VIEW PROJECT</Button>
                </div>
              </div>
            </PortfolioImage>
          </a>
        </Col>
        <Col xl="4">
          <a href="https://hypelance.com/">
            <PortfolioImage imageName={"hypelance"}>
              <div className="portfolio-text-mask">
                <div className="mask"></div>
                <div className="portfolio-text text-center">
                  <h3>HYPELANCE</h3>
                  <p>Invision Community</p>
                  <Button className="portfolio-button">VIEW PROJECT</Button>
                </div>
              </div>
            </PortfolioImage>
          </a>
        </Col>
      </Row>
    </FadeIn>
  </Container>
)

export default Portfolio
