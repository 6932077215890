import React from "react"
import FadeIn from "react-fade-in"

import Container from "react-bootstrap/Container"

import iconAbout from "../../static/images/icons/about.svg"

import SEO from "../components/seo"
import LayoutIndex from "../components/layoutIndex"
import Portfolio from "../components/portfolio"
import Contact from "../components/contact"
import Footer from "../components/footer"

const IndexPage = () => (
  <LayoutIndex>
    <SEO title="Home" />
    <FadeIn>
      <Container id="about" className="text-center">
        <img
          src={iconAbout}
          alt="Icon"
          width="24"
          className="icon gre-shadow"
        />
        <h2>HELLO MY NAME IS</h2>
        <h1>LOTUS</h1>
        <p>
          I&apos;m a skilled web designer and developer who strives for
          perfection as well as only the best for my clients. With experience in
          HTML, CSS, JS, NodeJS, React and Ionic app development, I have an
          extensible amount of skills applicable to many projects.
        </p>
      </Container>
    </FadeIn>
    <Portfolio />
    <div className="contact-footer">
      <Contact />
      <Footer />
    </div>
  </LayoutIndex>
)

export default IndexPage
