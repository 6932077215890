import { graphql, StaticQuery } from "gatsby"
import React from "react"
import PropTypes from "prop-types"

import BackgroundImage from "gatsby-background-image"

const PortfolioImage = ({ imageName, children }) => (
  <StaticQuery
    query={graphql`
      fragment portfolioWrapper on File {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      query {
        hypelance: file(relativePath: { eq: "portfolio/hypelance.png" }) {
          ...portfolioWrapper
        }
        ramshard: file(relativePath: { eq: "portfolio/ramshard.png" }) {
          ...portfolioWrapper
        }
        collabstr: file(relativePath: { eq: "portfolio/collabstr.png" }) {
          ...portfolioWrapper
        }
        fallback: file(relativePath: { eq: "portfolio/fallback.png" }) {
          ...portfolioWrapper
        }
      }
    `}
    render={images => {
      const imageData = eval("images." + imageName + ".childImageSharp.fluid")
      return (
        <BackgroundImage
          className="portfolio-wrapper container"
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          <>{children}</>
        </BackgroundImage>
      )
    }}
  />
)

PortfolioImage.propTypes = {
  imageName: PropTypes.string,
  children: PropTypes.node.isRequired,
}

PortfolioImage.defaultProps = {
  imageName: `fallback`,
}

export default PortfolioImage
