import React from "react"

import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"

import * as yup from "yup"
import { Formik, Field } from "formik"
import Swal from "sweetalert2"

const schema = yup.object({
  fullName: yup
    .string()
    .min(2, "Please enter a valid name.")
    .max(50, "Please enter a valid name.")
    .required("Required."),
  email: yup
    .string()
    .email("Please enter a valid email.")
    .required("Required."),
  discord: yup.string().max(50, "Invalid."),
  message: yup.string().min(10, "Message too short.").required("Required."),
})

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const FormFormik = () => (
  <Formik
    validationSchema={schema}
    onSubmit={(values, { setSubmitting }) => {
      fetch("/?no-cache=1", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "contact",
          ...values,
        }),
      })
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "You'll hear from me soon!",
            confirmButtonText: "Hooray!",
            confirmButtonColor: "#08B8D6",
          })
          setSubmitting(false)
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            confirmButtonColor: "#08B8D6",
          })
          setSubmitting(false)
        })
    }}
    initialValues={{ fullName: "", email: "", discord: "", message: "" }}
  >
    {({ handleSubmit, touched, errors, isSubmitting }) => (
      <Form
        name="contact"
        onSubmit={handleSubmit}
        netlify-honeypot="bot-field"
        data-netlify="true"
        noValidate
      >
        <input type="hidden" name="bot-field" />
        <Form.Group controlid="fullName">
          <Form.Label>Full Name</Form.Label>
          <Field name="fullName" type="text" className="form-control" />
          {touched.fullName && errors.fullName && (
            <p className="danger">{errors.fullName}</p>
          )}
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlid="formGridEmail">
            <Form.Label>E-mail Address</Form.Label>
            <Field name="email" type="email" className="form-control" />
            {touched.email && errors.email && (
              <p className="danger">{errors.email}</p>
            )}
          </Form.Group>
          <Form.Group as={Col} controlid="formGridDiscord">
            <Form.Label>Discord (Optional)</Form.Label>
            <Field name="discord" type="text" className="form-control" />
            {touched.discord && errors.discord && (
              <p className="danger">{errors.discord}</p>
            )}
          </Form.Group>
        </Form.Row>
        <Form.Group controlid="formMessage">
          <Form.Label>Message</Form.Label>
          <Field
            name="message"
            component="textarea"
            rows="6"
            className="form-control"
          />
          {touched.message && errors.message && (
            <p className="danger">{errors.message}</p>
          )}
        </Form.Group>
        <Button
          name="submit"
          type="submit"
          disabled={isSubmitting}
          className="contact-button"
        >
          SEND MESSAGE
        </Button>
      </Form>
    )}
  </Formik>
)

export default FormFormik
